import React from 'react';
import { httpClient } from '../../helpers/http-client';
import { Spinner } from 'react-bootstrap';

import './Loading.scss';

export default function Loading() {
    const [loading, setLoading] = React.useState(httpClient.loading.value);

    React.useEffect(() => {
        httpClient.loading
            .subscribe(loading => setLoading(loading));
    }, []);

    return (
        <div>
            {
                loading > 0 &&
                <div className="loading">
                    <div className="spinner">
                        {Array.from(Array(5).keys()).map((_, i) => 
                            <Spinner key={i} animation="grow" variant="light" style={{
                                WebkitAnimationDelay : `${0.10 * i}s`,
                                animationDelay: `${0.10 * i}s`
                            }}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}