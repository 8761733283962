
import { Observable, BehaviorSubject } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import Axios from  'axios-observable';
import { getToken } from './token.helper';
import { Method } from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

class HttpClient {
    loading = new BehaviorSubject(0);

    get<TResponseBody>(url: string): Observable<TResponseBody> {
        return this.fetch<TResponseBody>('GET', url);
    }

    post<TResponseBody>(url: string, data?: any): Observable<TResponseBody> {
        return this.fetch<TResponseBody>('POST', url, data);
    }

    put<TResponseBody>(url: string, data?: any): Observable<TResponseBody> {
        return this.fetch<TResponseBody>('PUT', url, data);
    }

    delete<TResponseBody>(url: string, data?: any): Observable<TResponseBody> {
        return this.fetch<TResponseBody>('DELETE', url, data);
    }

    fetch<TResponseBody>(method: Method, url: string, data?: any): Observable<TResponseBody> {
        this.loading.next(this.loading.value + 1);
        return Axios.request({
                baseURL: API_URL,
                url: url,
                method: method,
                headers: this.getHeaders(),
                data: data
            })
            .pipe(finalize(() => this.loading.next(this.loading.value - 1)))
            .pipe(map(x => x.data));
    }

    private getHeaders(): any {
        const headers = {
            'Content-Type': 'application/json'
        };

        const token = getToken();
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        return headers;
    }
}

export const httpClient = new HttpClient();
