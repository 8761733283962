import React from 'react';
import { Form } from 'react-bootstrap';
import { NumberPicker } from 'react-widgets';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import 'react-widgets/dist/css/react-widgets.css';

export default function NumberInput(props: any) {
    simpleNumberLocalizer();
    const { label, ...numberPickerProps } = props.meta;
    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <NumberPicker
                {...numberPickerProps}
                {...props.handler()}
                />
            {
                <Form.Control.Feedback className="d-block" type={props.valid ? 'valid' : 'invalid'}>
                    {
                        props.hasError('required') &&
                        <small>{`${label} es obligatorio`}</small>
                    }
                    {
                        props.hasError('max') &&
                        <small>{`${label} max`}</small>
                    }
                    {
                        props.hasError('min') &&
                        <small>{`${label} min`}</small>
                    }
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
}