import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Form, Card, Button, Container } from 'react-bootstrap';
import { FormBuilder, Validators, FieldGroup, FieldControl } from 'react-reactive-form';

import TextInput from '../controls/TextInput';
import { authService } from '../../services/auth.service';
import { LoginModel } from '../../models/user';

import logo from '../../assets/img/logo_horizontal.png';

export default function LoginPage(props: any) {
    const [error, setError] = React.useState<any>(null);
    const [formGroup] = React.useState(FormBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        rememberMe: false
    }));

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }

        const model: LoginModel = Object.assign(formGroup.value);

        authService.login(model)
            .subscribe(
                () => props.history.push('/licences'),
                (err) => showError((err.response && err.response.data && err.response.data.error) || 'Error al iniciar sesión')
            );
    }

    function showError(message: string) {
        setError(message);
        setTimeout(() => setError(null), 10000);
    }

    return (
        <Container className="page">
            <Row>
                <Col lg={6} md={8} sm={10} className="mx-auto text-center">
                    <img src={logo} width="180" className="mb-5" alt="EXCELBASIC" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={8} sm={10} className="mx-auto">
                    <Card className="shadow-lg border-0">
                        <Card.Body>
                            <div className="text-center">
                                <h5>INICIAR SESIÓN</h5>
                            </div>
                            <FieldGroup
                                control={formGroup}
                                render={() => (
                                    <Form className="form-row" autoComplete="false" onSubmit={handleSubmit}>
                                        <Col md={12}>
                                            <FieldControl
                                                name="username"
                                                render={TextInput}
                                                meta={{
                                                    label: 'Usuario'
                                                }}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <FieldControl
                                                name="password"
                                                render={TextInput}
                                                meta={{
                                                    label: 'Contraseña',
                                                    type: 'password'
                                                }}
                                            />
                                        </Col>
                                        {/* 
                                        <Col md={12}>
                                            <FieldControl
                                                name="rememberMe"
                                                render={CheckBox}
                                                meta={{ 
                                                    label: 'Recordarme'
                                                }}
                                            />
                                        </Col>
                                        */}
                                        <Col lg={6} md={8} sm={10} className="form-group mx-auto">
                                            <Button variant="primary" type="submit" className="rounded-pill" block>
                                                Iniciar sesión
                                            </Button>
                                        </Col>
                                    </Form>
                                )}
                            />
                            <Row>
                                <Col md={12}>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                </Col>
                                <Col md={12} className="text-center text-sm-right">
                                    <Link to="/reset-password" className="float-sm-left">Recuperar contraseña</Link>
                                    <br className="d-block d-sm-none" />
                                    <Link to="/register" className="float-sm-right">Crear una cuenta</Link>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
