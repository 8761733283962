import React from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'
import { FieldGroup, FieldControl, FormBuilder, Validators } from 'react-reactive-form'

import TextInput from '../../controls/TextInput';
import NumberInput from '../../controls/NumberInput';
import CheckBox from '../../controls/CheckBox';
import { Product } from '../../../models';

export default function ProductDetailComponent(props: any) {
    const formGroup = FormBuilder.group({
        idProduct: [null],
        name: ['', Validators.required],
        price: [0, [Validators.required, Validators.min(1)]],
        description: '',
        downloadUrl: '',
        logoUrl: '',
        imageUrl: '',
        documentationUrl: '',
        showButtonDownload: false,
        showButtonBuy: false,
        showButtonDocumentation: false
    });

    function handleReset(entity?: Product) {
        formGroup.reset({
            idProduct: null,
            name: '',
            price: 100,
            description: '',
            downloadUrl: '',
            logoUrl: '',
            imageUrl: '',
            documentationUrl: '',
            showButtonDownload: false,
            showButtonBuy: false,
            showButtonDocumentation: false
        });

        if (entity) {
            formGroup.patchValue(entity);
        }
    }

    function handleEnter(e: any) {
        handleReset(props.entity);
    }

    function handleHide() {
        handleReset();
        props.onHide();
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }
        
        props.onSubmit(Object.assign(formGroup.value));
        handleHide();
    }

    return (
        <Modal show={props.show} onHide={handleHide} onEnter={handleEnter}>
            <Modal.Header closeButton>
                <Modal.Title>PRODUCTO</Modal.Title>
            </Modal.Header>
            <FieldGroup
                control={formGroup}
                render={() => (
                    <Form autoComplete="false" onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <FieldControl
                                        name="name"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Nombre'
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="price"
                                        render={NumberInput}
                                        meta={{ 
                                            label: 'Nombre',
                                            max: 9999.99,
                                            min: 1,
                                            precision: 2,
                                            format: '$ #,###.00'
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="downloadUrl"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Url de descarga',
                                            className: 'text-monospace'
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="logoUrl"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Url del logo',
                                            className: 'text-monospace'
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="imageUrl"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Url de imagen de muestra',
                                            className: 'text-monospace'
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="documentationUrl"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Url de documentación',
                                            className: 'text-monospace'
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="description"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Descripción',
                                            as: 'textarea',
                                            rows: 5
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="order"
                                        render={NumberInput}
                                        meta={{ 
                                            label: 'Orden',
                                            max: 99,
                                            min: 1,
                                            precision: 0,
                                            format: '0'
                                        }}
                                    />
                                </Col>
                                <Col md={12} className="align-self-center">
                                    <FieldControl
                                        name="showButtonDownload"
                                        render={CheckBox}
                                        meta={{ 
                                            label: 'Mostrar botón de descarga'
                                        }}
                                    />
                                </Col>
                                <Col md={12} className="align-self-center">
                                    <FieldControl
                                        name="showButtonBuy"
                                        render={CheckBox}
                                        meta={{ 
                                            label: 'Mostrar botón de compra'
                                        }}
                                    />
                                </Col>
                                <Col md={12} className="align-self-center">
                                    <FieldControl
                                        name="showButtonDocumentation"
                                        render={CheckBox}
                                        meta={{ 
                                            label: 'Mostrar botón de documentación'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleHide}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit">
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            />
        </Modal>
    )
}
