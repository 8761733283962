import React from 'react';
import { Form } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

export default function DropDownList(props: any) {
    return (
        <Form.Group>
            <Form.Label>{props.meta.label}</Form.Label>
            <DropdownList
                data={props.meta.data} 
                valueField={props.meta.valueField}
                textField={props.meta.textField}
                itemComponent={props.meta.itemComponent}
                valueComponent={props.meta.valueComponent}
                filter={props.meta.filter}
                {...props.handler()}
                />
            {
                <Form.Control.Feedback className="d-block" type={props.valid ? 'valid' : 'invalid'}>
                    {
                        props.hasError('required') &&
                        <small>{`${props.meta.label} es obligatorio`}</small>
                    }
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
}