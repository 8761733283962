import React from 'react';
import { Modal, Button, Form, Col, Row, Alert } from 'react-bootstrap';
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";
import uuid from 'uuid';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import TextInput from '../../controls/TextInput';
import DropDownList from '../../controls/DropDownList';
import DateTimePicker from '../../controls/DateTimePicker';
import CheckBox from '../../controls/CheckBox';
import { productsService, usersService } from '../../../services';
import { Product, User, Licence } from '../../../models';

export default function LicenceDetailComponent(props: any) {
    const [alert, setAlert] = React.useState<any>(null);
    const [products, setProducts] = React.useState<Product[]>([]);
    const [users, setUsers] = React.useState<User[]>([]);

    React.useEffect(() => {
        const unsubscriber$ = new Subject();
        productsService.getAll()
            .pipe(takeUntil(unsubscriber$))
            .subscribe(
                products => setProducts(products),
                () => showAlert('Error al obtener los productos', 'danger')
            );
        usersService.getAll()
            .pipe(takeUntil(unsubscriber$))
            .subscribe(
                users => setUsers(users),
                () => showAlert('Error al obtener los usuarios', 'danger')
            );

        return () => {
            unsubscriber$.next();
            unsubscriber$.complete();
        };
    }, []);

    const formGroup = FormBuilder.group({
        idLicence: [null],
        idProduct: [null],
        idUser: [null],
        expiration: [moment().add(1, 'year').startOf('date').utc().toDate(), Validators.required],
        key: [uuid().toUpperCase(), Validators.required],
        paid: true,
        product: [null, Validators.required],
        user: [null, Validators.required]
    });

    formGroup.controls.product.valueChanges
        .subscribe((value: Product) => {
            formGroup.controls.idProduct.setValue(value && value.idProduct);
        });

    formGroup.controls.user.valueChanges
        .subscribe((value: User) => {
            formGroup.controls.idUser.setValue(value && value.idUser);
        });

    function handleReset(entity?: Licence) {
        formGroup.reset({
            idLicence: null,
            idProduct: null,
            idUser: null,
            expiration: moment().add(1, 'year').startOf('date').utc().toDate(),
            key: uuid().toUpperCase(),
            paid: true
        });

        if (entity) {
            formGroup.patchValue(entity);
        }
    }

    function handleEnter() {
        handleReset(props.entity);
    }

    function handleHide() {
        handleReset();
        props.onHide();
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }

        props.onSubmit(Object.assign(formGroup.value));
        handleHide();
    }

    function generateKey() {
        formGroup.controls.key.setValue(uuid().toUpperCase());
    }

    function showAlert(message: string, type: 'danger' | 'info') {
        setAlert({message: message, type});
        setTimeout(() => setAlert(null), 10000);
    }

    return (
        <Modal show={props.show} onHide={handleHide} onEnter={handleEnter}>
            <Modal.Header closeButton>
                <Modal.Title>LICENCIA</Modal.Title>
            </Modal.Header>
            <FieldGroup
                control={formGroup}
                render={() => (
                    <Form autoComplete="false" onSubmit={handleSubmit}>
                        <Modal.Body>
                            {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
                            <Row>
                                <Col md={6}>
                                    <FieldControl
                                        name="product"
                                        render={DropDownList}
                                        meta={{
                                            label: 'Producto',
                                            data: products,
                                            valueField: 'idProduct',
                                            textField: 'name'
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldControl
                                        name="expiration"
                                        render={DateTimePicker}
                                        meta={{
                                            label: 'Fecha de expiración'
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="user"
                                        render={DropDownList}
                                        meta={{
                                            label: 'Usuario',
                                            data: users,
                                            valueField: 'idUser',
                                            textField: 'email',
                                            itemComponent: ({ item }: any) => {
                                                return <span>
                                                    { 
                                                        item.firstName &&
                                                        <strong className="mr-1">
                                                            {item.firstName} {item.lastName}
                                                        </strong>
                                                    }
                                                    <span>
                                                        {item.email}
                                                    </span>
                                                </span>
                                            },
                                            valueComponent: ({ item }: any) => {
                                                return <span>
                                                    <strong className="mr-1">
                                                        {item.firstName} {item.lastName}
                                                    </strong>
                                                    <span>
                                                        {item.email}
                                                    </span>
                                                </span>
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="key"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Llave',
                                            readOnly: true,
                                            append: <Button variant="info" onClick={generateKey}>Generar</Button>
                                        }}
                                    />
                                </Col>
                                <Col md={12}>
                                    <FieldControl
                                        name="paid"
                                        render={CheckBox}
                                        meta={{ 
                                            label: 'Pagada'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleHide}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit">
                                Guardar
                                </Button>
                        </Modal.Footer>
                    </Form>
                )}
            />
        </Modal>
    );
}