import * as React from 'react';
import CarouselComponent from './carousel/Carousel'

import './Home.scss';
import logoHorizontal from '../../../assets/img/logo_horizontal.png';

export default function HomePage() {
    return (
        <div>
            <CarouselComponent />

            <section className="page-section" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <h1 className="title-about badge badge-primary">¿Qué es?</h1>
                            <p className="text-about">
                                Excelbasic para Pymes son herramientas
                                para la administracion de tu empresa
                                de manera segura, ágil y eficáz.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact" className="page-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-8 col-8 mx-auto text-center px-sm-5">
                            <img src={logoHorizontal} className="w-100" alt="ExcelBasic" />
                        </div>
                        <div className="col-lg-8">
                            <h1 className="title-contact">Contacto</h1>
                            <p className="text-contact">
                                ¿Listo para comenzar? ¡Eso es genial!
                                ¡Envíenos un correo electrónico y nos pondremos
                                en contacto con usted lo antes posible!
                            </p>
                            <a href="mailto:soporte@excelbasic.com" className="btn btn-primary btn-lg rounded-pill">
                                <i className="fas fa-envelope-open-text fa-lg mr-2"></i>
                                soporte@excelbasic.com
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}