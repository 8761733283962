import React from 'react'
import { Redirect } from 'react-router';
import { Alert, Card, Button, Badge } from 'react-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Table, TableColumn } from '../controls/Table';
import UserDetailComponent from './user-detail/UserDetail';
import UserChangePasswordModal from './user-change-password/UserChangePassword';
import { User, ChangePasswordModel } from '../../models';
import { authService, usersService as service } from '../../services';

export default function UsersCatalog() {
    const [showModalDetail, setShowModalDetail] = React.useState(false);
    const [showModalPassword, setShowModalPassword] = React.useState(false);
    const [datasource, setDatasource] = React.useState<User[]>([]);
    const [entity, setEntity] = React.useState<User>(null);
    const [alert, setAlert] = React.useState<any>(null);
    const columns: TableColumn<User>[] = [{
        Header: () => <Button variant="primary" size="sm" onClick={() => handleShowModalDetail()}>
            <i className="fa fa-plus"></i> Agregar
        </Button>,
        accessor: 'idUser',
        disableSortBy: true,
        columns: [{
            Header: 'EMAIL',
            accessor: 'email',
            className: 'text-nowrap',
            width: 'auto',
        },
        {
            Header: 'NOMBRE',
            accessor: 'firstName',
            className: 'text-nowrap',
            width: 'auto',
            Cell: ({ row: { original } }) => <>{original.firstName} {original.lastName}</>
        },
        {
            Header: 'ROL',
            accessor: 'rol',
            className: 'text-nowrap',
            width: 100,
        },
        {
            Header: 'ACTIVO',
            accessor: 'active',
            width: 80,
            className: 'text-nowrap text-center',
            Cell: ({ cell: { value } }) => (
                value
                    ? <Badge variant="success">SI</Badge>
                    : <Badge variant="warning">NO</Badge>
            )
        },
        {
            Header: 'ACCIONES',
            disableSortBy: true,
            width: 120,
            className: 'text-nowrap',
            Cell: ({ row: { original } }) => <>
                <Button variant="warning" size="sm" className="ml-1" title="Cambiar contraseña" onClick={() => { handleShowModalPassword(original); }}>
                    <i className="fas fa-key"></i>
                </Button>
                <Button variant="secondary" size="sm" className="ml-1" title="Editar" onClick={() => { handleShowModalDetail(original); }}>
                    <i className="fas fa-edit"></i>
                </Button>
                <Button size="sm" className="ml-1" 
                    variant={ original.active ? 'danger' : 'success' } 
                    title={original.active ? 'Desactivar' : 'Activar'} 
                    onClick={() => { updateEntity(Object.assign(original, { active: !original.active })); }}>
                    <i className={`fas fa-${original.active ? 'ban' : 'check'}`}></i>
                </Button>
                <Button variant="danger" size="sm" className="ml-1" 
                    title="Eliminar" 
                    onClick={() => { deleteEntity(original); }} 
                    disabled={original.licences && original.licences.length > 0}>
                    <i className="fas fa-trash"></i>
                </Button>
            </>
        }]
    }];

    React.useEffect(() => {
        const unsubscriber$ = new Subject();
        service.getAll()
            .pipe(takeUntil(unsubscriber$))
            .subscribe(
                users => setDatasource(users),
                () => showAlert('Error al obtener los usuarios', 'danger')
            );
        authService.onLogout
            .pipe(takeUntil(unsubscriber$))
            .subscribe(() => <Redirect to="/" />);

        return () => {
            unsubscriber$.next();
            unsubscriber$.complete();
        };
    }, []);
    
    function handleCloseModalDetail() {
        setShowModalDetail(false);
        setEntity(null);
    }

    function handleShowModalDetail(entity?: User) {
        setEntity(entity);
        setShowModalDetail(true);
    }

    function handleSubmitModalDetail(entity: User) {
        if (entity.idUser) {
            updateEntity(entity);
        } else {
            addEntity(entity);
        }
    }
    
    function handleCloseModalPassword() {
        setShowModalPassword(false);
        setEntity(null);
    }

    function handleShowModalPassword(entity?: User) {
        setEntity(entity);
        setShowModalPassword(true);
    }

    function handleSubmitModalPassword(model: ChangePasswordModel) {
        changePassword(model);
    }

    function addEntity(newEntity: User) {
        const idx = datasource.push(newEntity);

        setDatasource([...datasource]);
        service.save(newEntity)
            .subscribe(savedEntity => {
                Object.assign(newEntity, savedEntity);
                setDatasource([...datasource]);
                showAlert(`Se agregó el usuario ${newEntity.email}`, 'info');
            }, () => {
                setDatasource(datasource.slice(idx, 1));
                showAlert(`Error al guardar el usuario ${newEntity.email}`, 'danger');
            });
    }

    function updateEntity(updatedEntity: User) {
        const entity = datasource.find(x => x.idUser === updatedEntity.idUser);
        if (!entity) { return; }
        const copiedEntity = { ...entity };
        Object.assign(entity, updatedEntity);

        service.update(entity)
            .subscribe(savedEntity => {
                Object.assign(entity, savedEntity);
                setDatasource([...datasource]);
                showAlert(`Se actualizó el usuario ${updatedEntity.email}`, 'info');
            }, () => {
                showAlert(`Error al actualizar el usuario ${updatedEntity.email}`, 'danger');
                Object.assign(entity, copiedEntity);
                setDatasource([...datasource]);
            });
    }

    function changePassword(model: ChangePasswordModel) {
        const entity = datasource.find(x => x.idUser === model.idUser);
        if (!entity) { return; }

        service.changePassword(model)
            .subscribe(
                () => showAlert(`Se actualizó contraseña del usuario ${entity.email}`, 'info'),
                () => showAlert(`Error al actualizar contraseña del usuario ${entity.email}`, 'danger')
            );
    }

    function deleteEntity(deletedEntity: User) {
        const index = datasource.findIndex(x => x.idUser === deletedEntity.idUser);
        if (index < 0) { return; }

        datasource.splice(index, 1);
        setDatasource([...datasource]);

        service.delete(deletedEntity.idUser)
            .subscribe(() => {
                showAlert(`Se eliminó el usuario ${deletedEntity.email}`, 'info');
            }, () => {
                showAlert('Error al eliminar el usuario', 'danger');
                datasource.splice(index, 0, deletedEntity);
                setDatasource([...datasource]);
            });
    }

    function showAlert(message: string, type: 'danger' | 'info') {
        setAlert({ message: message, type });
        setTimeout(() => setAlert(null), 10000);
    }

    return (
        <div className="container page">
            <Card className="shadow-lg border-0">
                <Card.Body>
                    <h4>USUARIOS</h4>
                    {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
                    <Table columns={columns} datasource={datasource} />
                </Card.Body>
            </Card>

            <UserDetailComponent show={showModalDetail} 
                entity={entity}
                onHide={handleCloseModalDetail} 
                onSubmit={handleSubmitModalDetail}  
                />

            <UserChangePasswordModal show={showModalPassword} 
                idUser={entity && entity.idUser}
                onHide={handleCloseModalPassword} 
                onSubmit={handleSubmitModalPassword}  
                />
        </div>
    )
}
