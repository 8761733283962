import * as React from 'react';
import { Carousel } from 'react-bootstrap';

import banner01 from '../../../../assets/img/banner_01.png';
import banner02 from '../../../../assets/img/banner_02.png';
import arrowRight from '../../../../assets/img/arrow_right.png';
import arrowLeft from '../../../../assets/img/arrow_left.png';
import './Carousel.scss';

export default function CarouselComponent() {
    const nextIcon = <img src={arrowRight} className="img-fluid" alt="Siguiente"/>;
    const prevIcon = <img src={arrowLeft} className="img-fluid" alt="Anterior"/>;

    return (
        <Carousel className="carousel-home" interval={5000} nextIcon={nextIcon} prevIcon={prevIcon}>
            <Carousel.Item>
                <img className="d-block w-100" src={banner01} alt="ExcelBasic para PYMES" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={banner02} alt="Soluciones Creativas Para Tu Empresa" />
            </Carousel.Item>
        </Carousel>
    );
}