import React from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'
import { FieldGroup, FieldControl, FormBuilder, Validators } from 'react-reactive-form'

import TextInput from '../../controls/TextInput';
import DropDownList from '../../controls/DropDownList';
import CheckBox from '../../controls/CheckBox';
import { User, Rols } from '../../../models';
import CustomValidators from '../../../helpers/custom-validators';

export default function UserDetailComponent(props: any) {
    const formGroup = FormBuilder.group({
        idUser: null,
        email: ['', Validators.email],
        firstName: '',
        lastName: '',
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        confirmPassword: ['', [CustomValidators.match('password')]],
        rol: [null, Validators.required],
        active: false
    });

    function handleReset(entity?: User) {
        formGroup.reset({
            idUser: null,
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            rol: null,
            active: false
        });

        if (entity) {
            formGroup.patchValue(entity);
            formGroup.controls.password.disable();
            formGroup.controls.confirmPassword.disable();
        } else {
            formGroup.controls.password.enable();
            formGroup.controls.confirmPassword.enable();
        }
    }

    function handleEnter(e: any) {
        handleReset(props.entity);
    }

    function handleHide() {
        handleReset();
        props.onHide();
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }

        props.onSubmit(Object.assign(formGroup.value));
        handleHide();
    }

    return (
        <Modal show={props.show} onHide={handleHide} onEnter={handleEnter}>
            <Modal.Header closeButton>
                <Modal.Title>USUARIO</Modal.Title>
            </Modal.Header>
            <FieldGroup
                control={formGroup}
                render={() => (
                    <Form autoComplete="false" onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <FieldControl
                                        name="email"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Correo electrónico'
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldControl
                                        name="firstName"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Nombre'
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldControl
                                        name="lastName"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Apellido'
                                        }}
                                    />
                                </Col>
                                {
                                    !formGroup.controls.idUser.value && 
                                    <>
                                        <Col md={6}>
                                            <FieldControl
                                                name="password"
                                                render={TextInput}
                                                meta={{ 
                                                    label: 'Contraseña',
                                                    type: 'password'
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FieldControl
                                                name="confirmPassword"
                                                render={TextInput}
                                                meta={{ 
                                                    label: 'Confirmar contraseña',
                                                    type: 'password'
                                                }}
                                            />
                                        </Col>
                                    </>
                                }
                                <Col md={6}>
                                    <FieldControl
                                        name="rol"
                                        render={DropDownList}
                                        meta={{
                                            label: 'Rol',
                                            data: Rols
                                        }}
                                    />
                                </Col>
                                <Col md={6} className="align-self-center">
                                    <FieldControl
                                        name="active"
                                        render={CheckBox}
                                        meta={{ 
                                            label: 'Activo'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleHide}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit">
                                Guardar
                                </Button>
                        </Modal.Footer>
                    </Form>
                )}
            />
        </Modal>
    )
}
