import React from 'react';
import { useTable, Column, useSortBy, UseSortByOptions } from 'react-table';
import { Table as BootstrapTable } from 'react-bootstrap';

export type TableColumn<T extends object = {}> =  Column<T> & UseSortByOptions<T> & { className?: string; columns?: TableColumn<T>[], show?: boolean };
export type TableProps<T extends object = {}> = { columns: TableColumn<T>[]; datasource: T[]; loading?: boolean };

export function Table<T extends object = {}>(props: TableProps<T>) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<T>(
    {
      columns: props.columns,
      data: props.datasource,
    },
    useSortBy
  );

  return (
    <BootstrapTable striped bordered hover size="sm" responsive {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th
                {...column.getHeaderProps(
                  column.getSortByToggleProps({
                    title: '',
                    style: { maxWidth: column['maxWidth'], minWidth: column['width'] },
                  })
                )}
                className={column['className']}
                width={column['width']}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <i className="fas fa-caret-down float-right"></i>
                  ) : (
                    <i className="fas fa-caret-up float-right"></i>
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={cell.column['className']}
                  style={{ maxWidth: cell.column['maxWidth'], minWidth: cell.column['width'] }}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </BootstrapTable>
  );
}
