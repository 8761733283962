import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export default function TextInput(props: any) {
    return (
        <Form.Group>
            <Form.Label>{props.meta.label}</Form.Label>
            <InputGroup>
                {
                    props.meta.prepend &&
                    <InputGroup.Prepend>
                        {props.meta.prepend}
                    </InputGroup.Prepend>
                }
                <Form.Control 
                    type={props.meta.type || 'text'}
                    placeholder={props.meta.label}
                    size={props.meta.size} 
                    isInvalid={props.invalid}
                    readOnly={props.meta.readOnly}
                    as={props.meta.as}
                    rows={props.meta.rows}
                    {...props.handler()} 
                    />
                {
                    props.meta.append &&
                    <InputGroup.Append>
                        {props.meta.append}
                    </InputGroup.Append>
                }
            </InputGroup>
            {
                <Form.Control.Feedback className="d-block" type={props.valid ? 'valid' : 'invalid'}>
                    {
                        props.hasError('required') &&
                        <small>{`${props.meta.label} es obligatorio`}<br/></small>
                    }
                    {
                        props.hasError('match') &&
                        <small>{`${props.meta.label} debe ser igual Contraseña`}<br/></small>
                    }
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
}