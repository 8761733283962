import React from 'react'
import { Link } from 'react-router-dom';
import { Card, Alert, Form, Col, Button, Row, Container } from 'react-bootstrap'
import { Validators, FormBuilder, FieldGroup, FieldControl } from 'react-reactive-form';

import { authService } from '../../services';
import TextInput from '../controls/TextInput';

import logo from '../../assets/img/logo_horizontal.png';

export default function ConfirmResetPasswordPage(props: any) {
    const [user, setUser] = React.useState(authService.currentUser.value);
    const [alert, setAlert] = React.useState<string>(null);

    React.useEffect(() => {
        authService.currentUser
            .subscribe(loggedUser => {
                setUser(loggedUser);
            });
    }, []);

    const formGroup = FormBuilder.group({
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        confirmPassword: ['', [Validators.required]]
    });

    const confirmPasswordValidator = () => {
        if (formGroup.controls.password.value === formGroup.controls.confirmPassword.value) {
            formGroup.controls.confirmPassword.setErrors(null);
            return null;
        }
        formGroup.controls.confirmPassword.setErrors({ match: { value: true } });
        return { match: { value: true } };
    };

    formGroup.setValidators(confirmPasswordValidator);

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }

        const password: string = formGroup.value.password;
        const idUser: number = +props.match.params.idUser;
        const resetPasswordToken: string = props.match.params.resetPasswordToken;

        authService.confirmResetPassword(idUser, resetPasswordToken, password)
            .subscribe(
                () => {},
                (err) => showError((err.response && err.response.data && err.response.data.error) || 'Error al recuperar contraseña')
            );
    }

    function showError(message: string) {
        setAlert(message);
        setTimeout(() => setAlert(null), 10000);
    }

    return (
        <Container className="page">
            <Row>
                <Col md={12} className="mx-auto text-center">
                    <img src={logo} width="180" className="mb-5" alt="EXCELBASIC" />
                </Col>
            </Row>
            <Row>
                <Col lg={5} md={7} sm={10} className="mx-auto">
                    <Card className="shadow-lg border-0">
                        <Card.Body>
                            <h4 className="text-center">RECUPERAR CONTRASEÑA</h4>
                            {alert && <Alert variant="danger">{alert}</Alert>}
                            {
                                user
                                    ? <Row>
                                        <Col md={12}>
                                            <Alert variant="success">Se recuperó la contraseña exitosamente</Alert>
                                        </Col>
                                        <Col lg={10} md={8} className="mx-auto">
                                            <Link to="/" role="button" className="btn btn-secondary btn-block rounded-pill">
                                                Ir a inicio
                                            </Link>
                                        </Col>
                                    </Row>
                                    : <FieldGroup
                                        control={formGroup}
                                        render={() => (
                                            <Form className="form-row" autoComplete="false" onSubmit={handleSubmit}>
                                                <Col md={6}>
                                                    <FieldControl
                                                        name="password"
                                                        render={TextInput}
                                                        meta={{
                                                            label: 'Contraseña',
                                                            type: 'password'
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <FieldControl
                                                        name="confirmPassword"
                                                        render={TextInput}
                                                        meta={{
                                                            label: 'Confirmar contraseña',
                                                            type: 'password'
                                                        }}
                                                    />
                                                </Col>
                                                <Col lg={10} md={8} className="mx-auto">
                                                    <Button variant="primary" type="submit" className="rounded-pill" block>
                                                        Recuperar contraseña
                                                    </Button>
                                                </Col>
                                            </Form>
                                        )} />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
