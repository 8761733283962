import { BehaviorSubject, Subject, Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { httpClient } from '../helpers/http-client';
import { setToken, clearToken, getToken } from '../helpers/token.helper';
import { LoginModel, TokenModel, UserSessionModel, RegisterModel, ChangePasswordModel } from '../models';

class AuthService {
    readonly controller = 'auth';
    currentUser = new BehaviorSubject(this.getCurrentUser());
    onLogout = new Subject<void>();

    login(model: LoginModel): Observable<TokenModel> {
        return httpClient.post<TokenModel>(`${this.controller}/login`, model)
            .pipe(catchError((err) => {
                this.currentUser.next(null);
                return throwError(err);
            }))
            .pipe(tap(tokenModel => {
                this.setPrincipal(tokenModel.token, model.rememberMe);
            }));
    }

    logout() {
        clearToken();
        this.currentUser.next(null);
        this.onLogout.next();
    }

    register(model: RegisterModel): Observable<TokenModel> {
        return httpClient.post<TokenModel>(`${this.controller}/register`, model)
            .pipe(catchError((err) => {
                this.currentUser.next(null);
                return throwError(err);
            }))
            .pipe(tap(tokenModel => {
                this.setPrincipal(tokenModel.token);
            }));
    }

    resetPassword(email: string): Observable<void> {
        return httpClient.post<void>(`${this.controller}/resetPassword`, {
            email
        });
    }

    changePassword(model: ChangePasswordModel): Observable<void> {
        return httpClient.put<void>(`${this.controller}/changePassword`, model);
    }

    confirmResetPassword(idUser: number, resetPasswordToken: string, password: string): Observable<TokenModel> {
        return httpClient.post<TokenModel>(`${this.controller}/confirmResetPassword`, {
            idUser,
            resetPasswordToken,
            password
        })
        .pipe(catchError((err) => {
            this.currentUser.next(null);
            return throwError(err);
        }))
        .pipe(tap(tokenModel => {
            this.setPrincipal(tokenModel.token);
        }));
    }

    setPrincipal(token: string, rememberMe = false): void {
        if (!token) { throw new Error('token argument is falsy'); }
        
        setToken(token, rememberMe);
        this.currentUser.next(this.getCurrentUser());
    }

    private getCurrentUser(): UserSessionModel {
        const token = getToken();

        if (!token) { return null; }

        try {
            const decode = jwt_decode<any>(token);

            if (moment().isAfter(decode.exp * 1000)) { return null; }

            return {
                idUser: decode.idUser,
                email: decode.email,
                firstName: decode.firstName,
                lastName: decode.lastName,
                rol: decode.rol
            };
        } catch (error) {
            return null;
        }
    }
}

export const authService = new AuthService();