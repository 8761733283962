import React from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Card, Alert, Badge } from 'react-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment'

import { Table, TableColumn } from '../controls/Table';
import LicenceDetailComponent from './licence-detail/LicenceDetail';
import { licenciasService } from '../../services/licences.service';
import { authService } from '../../services/auth.service';
import { Licence } from '../../models/licence';
import { Rol } from '../../models';

export default function LicencesCatalog(props: any) {
    const [user] = React.useState(authService.currentUser.value);
    const [showModal, setShowModal] = React.useState(false);
    const [datasource, setDatasource] = React.useState<Licence[]>([]);
    const [entity, setEntity] = React.useState<Licence>(null);
    const [alert, setAlert] = React.useState<any>(null);
    
    const columns: TableColumn<Licence>[] = [{
        Header: () => (
            user.rol === Rol.Admin
                ? <Button variant="primary" size="sm" onClick={() => handleShowModal()}>
                    <i className="fa fa-plus"></i> Agregar
                </Button>
                : <Link to="/products" className="btn btn-primary btn-sm">
                    <i className="fa fa-dollar-sign"></i> Comprar producto
                </Link>
        ),
        accessor: 'idLicence',
        disableSortBy: true,
        columns: [{
            Header: 'PRODUCTO',
            accessor: 'product.name' as any,
            className: 'text-nowrap',
            width: 130,
        },
        {
            Header: 'CORREO',
            accessor: 'user.email' as any,
            width: 'auto',
            className: 'text-nowrap',
            show: user && user.rol === Rol.Admin
        },
        {
            Header: 'FECHA EXPIRACIÓN',
            accessor: 'expiration',
            width: 180,
            className: 'text-center text-nowrap',
            Cell: ({ cell: { value } }) => moment(value).format('DD/MM/YYYY')
        },
        {
            Header: 'LLAVE',
            accessor: 'key',
            width: 'auto',
            className: 'text-nowrap',
            Cell: ({ cell: { value } }) => <code>{value}</code>
        },
        {
            Header: 'SERIAL',
            accessor: 'serial',
            width: 'auto',
            className: 'text-nowrap',
            show: user && user.rol === Rol.Admin,
            Cell: ({ cell: { value } }) => <code>{value}</code>
        },
        {
            Header: 'PAGADA',
            accessor: 'paid',
            width: 90,
            className: 'text-nowrap text-center',
            show: user && user.rol === Rol.Admin,
            Cell: ({ cell: { value } }) => (
                value
                    ? <Badge variant="success">SI</Badge>
                    : <Badge variant="warning">NO</Badge>
            )
        },
        {
            Header: 'ACCIONES',
            disableSortBy: true,
            width: 160,
            className: 'text-nowrap',
            show: user && user.rol === Rol.Admin,
            Cell: ({ row: { original } }) => <>
                <Button variant="info" size="sm" title="Enviar email" onClick={() => { sendEmailLicence(original); }}>
                    <i className="fas fa-envelope"></i>
                </Button>
                {
                    process.env.NODE_ENV === 'development' &&
                    <Button variant="secondary" size="sm" className="ml-1" title="Editar" onClick={() => { handleShowModal(original); }}>
                        <i className="fas fa-edit"></i>
                    </Button>
                }
                <Button variant="danger" size="sm" className="ml-1" title="Eliminar" onClick={() => { deleteEntity(original); }}>
                    <i className="fas fa-trash"></i>
                </Button>
                {
                    !original.paid &&
                    <Button variant="warning" size="sm" className="ml-1" title="Pagar" onClick={() => { payLicence(original); }}>
                        <i className="fas fa-dollar-sign px-1"></i>
                    </Button>
                }
            </>
        }]
    }];

    React.useEffect(() => {
        const unsubscriber$ = new Subject();
        licenciasService.getAll()
            .pipe(takeUntil(unsubscriber$))
            .subscribe(
                licencias => setDatasource(licencias),
                () => showAlert('Error al obtener las licencias', 'danger')
            );
        authService.onLogout
            .pipe(takeUntil(unsubscriber$))
            .subscribe(() => <Redirect to="/" />);

        return () => {
            unsubscriber$.next();
            unsubscriber$.complete();
        };
    }, []);

    function handleCloseModal() {
        setShowModal(false);
        setEntity(undefined);
    }

    function handleShowModal(entity?: Licence) {
        setEntity(entity);
        setShowModal(true);
    }

    function handleSubmitModal(entity: Licence) {
        if (entity.idLicence) {
            updateEntity(entity);
        } else {
            addEntity(entity);
        }
    }

    function showAlert(message: string, type: 'danger' | 'info') {
        setAlert({ message: message, type });
        setTimeout(() => setAlert(null), 10000);
    }

    function addEntity(newEntity: Licence) {
        newEntity.expiration = moment(newEntity.expiration).utc().toDate();
        const idx = datasource.push(newEntity);

        setDatasource([...datasource]);
        licenciasService.save(newEntity)
            .subscribe(savedLicence => {
                Object.assign(newEntity, {
                    idLicence: savedLicence.idLicence,
                    expiration: moment(savedLicence.expiration).utc().toDate()
                });
                setDatasource([...datasource]);
            }, () => {
                showAlert('Error al guardar la licencia', 'danger');
                setDatasource(datasource.slice(idx, 1));
            });
    }

    function updateEntity(updatedEntity: Licence) {
        const entity = datasource.find(x => x.idLicence === updatedEntity.idLicence);
        if (!entity) { return; }
        const copiedEntity = { ...entity };
        Object.assign(entity, updatedEntity);

        setDatasource([...datasource]);
        licenciasService.update(entity)
            .subscribe(savedEntity => {
                Object.assign(entity, savedEntity);
                setDatasource([...datasource]);
                showAlert(`Se actualizó la licencia ${entity.key}`, 'info');
            }, () => {
                showAlert('Error al actualizar la licencia', 'danger');
                Object.assign(entity, copiedEntity);
                setDatasource([...datasource]);
            });
    }

    function deleteEntity(deleteEntity: Licence) {
        const index = datasource.findIndex(x => x.idLicence === deleteEntity.idLicence);
        if (index < 0) { return; }

        datasource.splice(index, 1);
        setDatasource([...datasource]);
        licenciasService.delete(deleteEntity.idLicence)
            .subscribe(
                () => showAlert(`Se eliminó la licencia ${deleteEntity.key}`, 'info'),
                () => {
                    showAlert('Error al eliminar la licencia', 'danger');
                    datasource.splice(index, 0, deleteEntity);
                    setDatasource([...datasource]);
                }
            );
    }

    function sendEmailLicence(licence: Licence) {
        if (!licence || !licence.idLicence) { return; }
        licenciasService.sendEmail(licence.idLicence)
            .subscribe(
                () => showAlert(`Se envió el correo con la licencia ${licence.key}`, 'info'),
                () => showAlert('Error al enviar el correo de la licencia', 'danger')
            );
    }

    function payLicence(licence: Licence) {
        const copiedLicence = { ...licence };
        licence.paid = true;

        licenciasService.pay(licence.idLicence)
            .subscribe(
                () => showAlert(`Se actualizó la licencia ${licence.key}`, 'info'),
                () => {
                    showAlert('Error al actualizar la licencia', 'danger');
                    Object.assign(licence, copiedLicence);
                    setDatasource([...datasource]);
                }
            );
    }

    return (
        <div className="container-fluid page">
            <Card className="shadow-lg border-0 mx-auto" style={{ maxWidth: '1260px' }}>
                <Card.Body>
                    <h4>LICENCIAS</h4>
                    {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
                    <Table columns={columns} datasource={datasource}/>
                </Card.Body>
            </Card>

            <LicenceDetailComponent show={showModal}
                entity={entity}
                onHide={handleCloseModal}
                onSubmit={handleSubmitModal}
            />
        </div>
    );
}