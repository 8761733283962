import React from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'
import { FieldGroup, FieldControl, FormBuilder, Validators } from 'react-reactive-form'

import TextInput from '../../controls/TextInput';
import { ChangePasswordModel } from '../../../models';
import CustomValidators from '../../../helpers/custom-validators';

type UserChangePasswordProps = {
    show: boolean;
    idUser?: number;
    onHide: () => void;
    onSubmit: (entity: ChangePasswordModel) => void;
};
export default function UserChangePasswordModal(props: UserChangePasswordProps) {
    const formGroup = FormBuilder.group({
        idUser: null,
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        confirmPassword: ['', [CustomValidators.match('password')]]
    });

    function handleReset(idUser?: number) {
        formGroup.reset({
            idUser: idUser,
            password: '',
            confirmPassword: ''
        });
    }

    function handleEnter(e: any) {
        handleReset(props.idUser);
    }

    function handleHide() {
        handleReset();
        props.onHide();
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }

        props.onSubmit(Object.assign(formGroup.value));
        handleHide();
    }

    return (
        <Modal show={props.show} onHide={handleHide} onEnter={handleEnter}>
            <Modal.Header closeButton>
                <Modal.Title>USUARIO</Modal.Title>
            </Modal.Header>
            <FieldGroup
                control={formGroup}
                render={() => (
                    <Form autoComplete="false" onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col md={6}>
                                    <FieldControl
                                        name="password"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Contraseña',
                                            type: 'password'
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldControl
                                        name="confirmPassword"
                                        render={TextInput}
                                        meta={{ 
                                            label: 'Confirmar contraseña',
                                            type: 'password'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleHide}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit">
                                Guardar
                                </Button>
                        </Modal.Footer>
                    </Form>
                )}
            />
        </Modal>
    )
}
