import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { PaypalPayModel, PaymentResponseModel } from '../models';
import { httpClient } from '../helpers/http-client';
import { authService } from './auth.service';

class PaymentsService {
    readonly controller = 'payments';

    paypal(model: PaypalPayModel): Observable<PaymentResponseModel> {
        return httpClient.post<PaymentResponseModel>(`${this.controller}/paypal`, model)
            .pipe(catchError((err) => {
                authService.currentUser.next(null);
                return throwError(err);
            }))
            .pipe(tap(payment => {
                authService.setPrincipal(payment.token);
            }));
    }
}

export const paymentsService = new PaymentsService();