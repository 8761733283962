import { AbstractControl, FormGroup } from "react-reactive-form";

export default class CustomValidators {
    static match(matchControlName: string) {
        return (control: AbstractControl) => {
            const formGroup = control.parent as FormGroup;

            if (formGroup && formGroup.controls[matchControlName].value === control.value) {
                control.setErrors(null);
                return null;
            }
            return { match: { value: true } };
        }
    };
}