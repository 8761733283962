import React from 'react'
import ReactDOM from 'react-dom';
import { Modal, Alert } from 'react-bootstrap';

import { Product, PaypalPayModel } from '../../../../models';
import { authService, paymentsService } from '../../../../services';

type ProductCheckoutProps = {
    show: boolean,
    product: Product,
    onSuccess: () => void,
    onHide: () => void
};
export default function ProductCheckoutComponent(props: ProductCheckoutProps) {
    const paypal = (window as any).paypal;
    let PayPalButton = paypal.Buttons.driver('react', { React, ReactDOM });
    const [user] = React.useState(authService.currentUser.value);

    const paypalProps = {
        commit: true,
        style: {
            size: 'small',
            color: 'blue',
            shape: 'pill',
            label: 'buynow'
        }
    }

    function createOrder(data: any, actions: any) {
        return actions.order.create({
            purchase_units: [{
                reference_id: props.product.idProduct,
                description: `Aplicación ${props.product.name}`,
                amount: {
                    value: props.product.price,
                    currency_code: 'MXN'
                },
                soft_descriptor: props.product.name
            }],
            application_context: {
                brand_name: 'ExcelBasic',
                shipping_preference: 'NO_SHIPPING'
            }
        });
    }

    async function onApprove(data: any, actions: any) {
        const model: PaypalPayModel = {
            idOrder: data.orderID,
            idPayer: data.payerID
        };

        await paymentsService.paypal(model).toPromise();
        
        props.onSuccess();
        handleHide();
    }

    function onError() {
    }

    function onCancel() {

    }

    function handleEnter() {

    }

    function handleHide() {
        props.onHide();
    }

    return (
        <Modal show={props.show} onHide={handleHide} onEnter={handleEnter}>
            <Modal.Header closeButton>
                <Modal.Title className="text-uppercase">PAGAR {props.product && props.product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-3">
                    <img src={props.product && props.product.logoUrl} className="img-fluid" alt={props.product && props.product.name}></img>
                    <h2>{props.product && props.product.name}</h2>
                </div>
                <Alert variant="warning">
                    La compra se registrará al correo {user ? <strong>{user.email}</strong> : <>de paypal que realice la compra</>}
                </Alert>
                <PayPalButton
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onCancel={onCancel}
                    onError={onError}
                    {...paypalProps} />
            </Modal.Body>
        </Modal>
    )
}
