import { Observable } from 'rxjs';
import { User, ChangePasswordModel } from '../models';
import { httpClient } from '../helpers/http-client';

class UsersService {
    readonly controller = 'users';

    getAll(): Observable<User[]> {
        return httpClient.get<User[]>(this.controller);
    }

    save(entity: User): Observable<User> {
        return httpClient.post<User>(this.controller, entity);
    }

    update(entity: User): Observable<User> {
        return httpClient.put<User>(`${this.controller}/${entity.idUser}`, entity);
    }

    changePassword(model: ChangePasswordModel): Observable<User> {
        return httpClient.put<User>(`${this.controller}/${model.idUser}/changePassword`, model);
    }

    delete(id: number): Observable<User> {
        return httpClient.delete<User>(`${this.controller}/${id}`);
    }
}

export const usersService = new UsersService();