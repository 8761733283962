import React from 'react'
import { Redirect } from 'react-router';
import { Alert, Card, Button } from 'react-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Table, TableColumn } from '../controls/Table';
import ProductDetailComponent from './product-detail/ProductDetail';
import { Product } from '../../models';
import { authService, productsService as service } from '../../services';

export default function ProductsCatalog(props: any) {
    const [showModal, setShowModal] = React.useState(false);
    const [datasource, setDatasource] = React.useState<Product[]>([]);
    const [entity, setEntity] = React.useState<Product>(null);
    const [alert, setAlert] = React.useState<any>(null);
    const columns: TableColumn<Product>[] = [{
        Header: () => <Button variant="primary" size="sm" onClick={() => handleShowModal()}>
            <i className="fa fa-plus"></i> Agregar
        </Button>,
        accessor: 'idProduct',
        disableSortBy: true,
        columns: [{
            Header: 'NOMBRE',
            accessor: 'name',
            width: 'auto',
            className: 'text-nowrap'
        }, {
            Header: 'PRECIO',
            accessor: 'price',
            width: 100,
            className: 'text-nowrap',
            Cell: ({ cell: { value } }) => <>$ {value.toFixed(2)}</>
        }, {
            Header: 'URL DE DESCARGA',
            accessor: 'downloadUrl',
            width: 200,
            className: 'text-nowrap',
            Cell: ({ cell: { value } }) => <code><a href={value} rel="noopener noreferrer" target="_blank">{value}</a></code>
        }, {
            Header: 'DESCRIPCIÓN',
            accessor: 'description',
            width: 200,
            maxWidth: 200,
            className: 'text-truncate',
            Cell: ({ cell: { value } }) => <span title={value}>{value}</span>
        }, {
            Header: 'ACCIONES',
            disableSortBy: true,
            width: 110,
            className: 'text-nowrap',
            Cell: ({ row: { original } }) => <>
                <Button variant="secondary" size="sm" className="ml-1" title="Editar" onClick={() => { handleShowModal(original); }}>
                    <i className="fas fa-edit"></i>
                </Button>
                <Button variant="danger" size="sm" className="ml-1" title="Eliminar" onClick={() => { deleteEntity(original); }}>
                    <i className="fas fa-trash"></i>
                </Button>
            </>
        }]
    }];

    React.useEffect(() => {
        const unsubscriber$ = new Subject();
        service.getAll()
            .pipe(takeUntil(unsubscriber$))
            .subscribe(
                products => setDatasource(products),
                () => showAlert('Error al obtener las licencias', 'danger')
            )
        authService.onLogout
            .pipe(takeUntil(unsubscriber$))
            .subscribe(() => <Redirect to="/" />);

        return () => {
            unsubscriber$.next();
            unsubscriber$.complete();
        };
    }, []);

    function handleCloseModal() {
        setShowModal(false);
        setEntity(null);
    }

    function handleShowModal(entity?: Product) {
        setEntity(entity);
        setShowModal(true);
    }

    function handleSubmitModal(entity: Product) {
        if (entity.idProduct) {
            updateEntity(entity);
        } else {
            addEntity(entity);
        }
    }

    function addEntity(newEntity: Product) {
        const idx = datasource.push(newEntity);

        setDatasource([...datasource]);
        service.save(newEntity)
            .subscribe(savedEntity => {
                Object.assign(newEntity, savedEntity);
                setDatasource([...datasource]);
                showAlert(`Se agregó el producto ${newEntity.name}`, 'info');
            }, () => {
                setDatasource(datasource.slice(idx, 1));
                showAlert('Error al guardar el producto', 'danger');
            });
    }

    function updateEntity(updatedEntity: Product) {
        const entity = datasource.find(x => x.idProduct === updatedEntity.idProduct);
        if (!entity) { return; }
        const copiedEntity = { ...entity };
        Object.assign(entity, updatedEntity);

        service.update(entity)
            .subscribe(savedEntity => {
                Object.assign(entity, savedEntity);
                setDatasource([...datasource]);
                showAlert(`Se actualizó el producto ${entity.name}`, 'info');
            }, () => {
                showAlert('Error al actualizar el producto', 'danger');
                Object.assign(entity, copiedEntity);
                setDatasource([...datasource]);
            });
    }

    function deleteEntity(deletedEntity: Product) {
        const index = datasource.findIndex(x => x.idProduct === deletedEntity.idProduct);
        if (index < 0) { return; }

        datasource.splice(index, 1);
        setDatasource([...datasource]);

        service.delete(deletedEntity.idProduct)
            .subscribe(() => {
                showAlert(`Se eliminó el producto ${deletedEntity.name}`, 'info');
            }, () => {
                showAlert('Error al eliminar el producto', 'danger');
                datasource.splice(index, 0, deletedEntity);
                setDatasource([...datasource]);
            });
    }

    function showAlert(message: string, type: 'danger' | 'info') {
        setAlert({ message: message, type });
        setTimeout(() => setAlert(null), 10000);
    }

    return (
        <div className="container page">
            <Card className="shadow-lg border-0">
                <Card.Body>
                    <h4>PRODUCTOS</h4>
                    {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
                    <Table columns={columns} datasource={datasource} />
                </Card.Body>
            </Card>

            <ProductDetailComponent show={showModal}
                entity={entity}
                onHide={handleCloseModal}
                onSubmit={handleSubmitModal}
            />
        </div>
    )
}
