import { Licence } from "./licence";

export interface User {
    idUser: number;
    email: string;
    firstName: string;
    lastName: string;
    rol: Rol;
    active: boolean;
    licences?: Licence[];
}

export interface UserSessionModel {
    idUser: number;
    email: string;
    firstName: string;
    lastName: string;
    rol: Rol;
}

export interface LoginModel {
    username: string;
    password: string;
    rememberMe: boolean;
}

export interface RegisterModel {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
}

export interface TokenModel {
    token: string;
    expire: string;
}

export interface ChangePasswordModel {
    idUser?: number;
    password: string;
    confirmPassword: string;
}

export enum Rol {
    Admin = 'ADMIN', 
    Customer = 'CUSTOMER'
}

export const Rols = [ Rol.Admin, Rol.Customer ];