import React from 'react';
import { RouteChildrenProps } from 'react-router';

import ProductCheckoutComponent from './product-checkout/ProductCheckout';
import { productsService } from '../../../services';
import { Product } from '../../../models';

import './Products.scss';

export default function ProductsPage(props: RouteChildrenProps) {
    const [products, setProducts] = React.useState<Product[]>([]);
    const [productCheckOut, setProductCheckOut] = React.useState<{ 
        show: boolean, 
        product: Product
    }>();

    const cssBgProductsColors = [
        'bg-product-yellow',
        'bg-product-green',
        'bg-product-brown'
    ];

    const cssBgProductsTexture = [
        'bg-products-light-left',
        'bg-products-dark-right'
    ];

    React.useEffect(() => {
        productsService.getAll()
            .subscribe(products => {
                setProducts(products);
            });
    }, [setProductCheckOut]);
    
    function handleShowModal(product: Product) {
        setProductCheckOut({ show: true, product });
    }
    
    function handleHideModal() {
        setProductCheckOut(null);
    }
    
    function handleSuccessModal() {
        setProductCheckOut(null);
        props.history.push('/licences');
    }

    return (
        <div className="mt-n1">
            {
                products.map((x, i) => 
                    <div key={x.idProduct} className={`col-12 ${cssBgProductsColors[i % 3]} ${cssBgProductsTexture[i % 2]}`}>
                        <div className="container">
                            <div className="row">
                                <div className={`col-lg-6 pl-5 pb-5 pb-md-0 ${i % 2 === 0 ? 'order-md-12' : ''}`}>
                                    <h1 className="title-product">
                                        <img src={x.logoUrl} className="img-product-logo" alt={x.name}></img>
                                        {x.name}
                                    </h1>
                                    <p className="description-product" dangerouslySetInnerHTML={{__html: x.description}}></p>
                                    {
                                        x.showButtonBuy 
                                        && <p className="text-light mb-4 bg-success px-3 py-2 rounded">
                                            <span className=" h4 text-light">Precio <span className="text-nowrap">$ {x.price.toFixed(2)}</span> por computadora al año</span>
                                        </p>
                                    }
                                    { 
                                        x.showButtonDownload 
                                        ? <a href={x.downloadUrl} 
                                            role="button"
                                            className="btn btn-brown btn-product rounded-pill mr-2" 
                                            rel="noopener noreferrer" 
                                            target="_blank">
                                            <strong>Prueba Gratuita</strong>
                                        </a>
                                        : null
                                    }
                                    {
                                        x.showButtonBuy 
                                        ? <button 
                                            className="btn btn-brown btn-product rounded-pill mr-2" 
                                            onClick={() => handleShowModal(x)}>
                                            <strong>Comprar</strong>
                                        </button>
                                        : null
                                    }
                                    {
                                        x.showButtonDocumentation 
                                        ? <a href={x.documentationUrl} 
                                            role="button" 
                                            className="btn btn-brown btn-product rounded-pill mr-2" >
                                            <strong>Documentación</strong>
                                        </a>
                                        : null
                                    }
                                </div>
                                <div className={`col-md-6 vertical-align text-center ${i % 2 === 0 ? 'text-md-right order-md-1' : 'text-md-left'}`}>
                                    <img src={x.imageUrl} className="img-product img-fluid" alt={x.name} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <ProductCheckoutComponent {...productCheckOut} onHide={handleHideModal} onSuccess={handleSuccessModal}/>
        </div>
    );
}