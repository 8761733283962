import React from 'react'
import { Link } from 'react-router-dom';
import { Card, Alert, Form, Col, Button, Row } from 'react-bootstrap'
import { Validators, FormBuilder, FieldGroup, FieldControl } from 'react-reactive-form';

import { authService } from '../../services';
import TextInput from '../controls/TextInput';

import logo from '../../assets/img/logo_horizontal.png';

export default function ResetPasswordPage(props: any) {
    const [alert, setAlert] = React.useState<string>(null);
    const [success, setSuccess] = React.useState<boolean>(false);

    const formGroup = FormBuilder.group({
        email: [null, [Validators.required, Validators.email]]
    });

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }

        const email: string = formGroup.value.email;

        authService.resetPassword(email)
            .subscribe(
                () => setSuccess(true),
                (err) => {
                    setSuccess(false);
                    showError((err.response && err.response.data && err.response.data.error) || 'Error al recuperar contraseña');
                }
            );
    }

    function showError(message: string) {
        setAlert(message);
        setTimeout(() => setAlert(null), 10000);
    }

    return (
        <div className="container page">
            <Row>
                <Col md={12} className="mx-auto text-center">
                    <img src={logo} width="180" className="mb-5" alt="EXCELBASIC" />
                </Col>
            </Row>
            <Row>
                <Col lg={5} md={7} sm={10} className="mx-auto">
                    <Card className="shadow-lg border-0">
                        <Card.Body>
                            <h4 className="text-center">RECUPERAR CONTRASEÑA</h4>
                            {alert && <Alert variant="danger">{alert}</Alert>}
                            {
                                success
                                    ? <Row>
                                        <Col md={12}>
                                            <Alert variant="success">Se envió exitosamente la solicitud de recuperación de contraseña al correo electrónico</Alert>
                                        </Col>
                                        <Col lg={10} md={8} className="mx-auto">
                                            <Link to="/login" role="button" className="btn btn-secondary btn-block rounded-pill">
                                                Ir a inicio de sesión
                                            </Link>
                                        </Col>
                                    </Row>
                                    : <FieldGroup
                                        control={formGroup}
                                        render={() => (
                                            <Form className="form-row" autoComplete="false" onSubmit={handleSubmit}>
                                                <Col md={12}>
                                                    <FieldControl
                                                        name="email"
                                                        render={TextInput}
                                                        meta={{
                                                            label: 'Correo electrónico',
                                                            type: 'email'
                                                        }}
                                                    />
                                                </Col>
                                                <Col lg={10} md={8} className="mx-auto">
                                                    <Button variant="primary" type="submit" className="rounded-pill" block>
                                                        Recuperar contraseña
                                                    </Button>
                                                </Col>
                                            </Form>
                                        )} />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
