import React from 'react';
import { Form } from 'react-bootstrap';
import { DateTimePicker as DateTimePickerWidget } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

export default function DateTimePicker(props: any) {
    moment.locale('es');
    momentLocalizer();

    return (
        <Form.Group>
            <Form.Label>{props.meta.label}</Form.Label>
            <DateTimePickerWidget
                defaultValue={props.meta.defaultValue ||new Date()}
                format={props.meta.format || 'DD/MM/YYYY'}
                time={props.meta.time || false}
                culture={props.meta.culture || 'es'}
                readOnly={props.meta.readOnly}
                {...props.handler()}
                />
            {
                <Form.Control.Feedback className="d-block" type={props.valid ? 'valid' : 'invalid'}>
                    {
                        props.hasError('required') &&
                        <small>{`${props.meta.label} es obligatorio`}</small>
                    }
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
}