import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Licence } from '../models';
import { httpClient } from '../helpers/http-client';

class LicenciasService {
    readonly controller = 'licences';

    getAll(): Observable<Licence[]> {
        return httpClient.get<Licence[]>(this.controller)
            .pipe(map(licences => licences.map(x => {
                x.expiration = moment(x.expiration).utc().toDate();
                return x;
            })));
    }

    save(entity: Licence): Observable<Licence> {
        return httpClient.post<Licence>(this.controller, entity);
    }

    update(entity: Licence): Observable<Licence> {
        return httpClient.put<Licence>(`${this.controller}/${entity.idLicence}`, entity);
    }

    delete(id: number): Observable<Licence> {
        return httpClient.delete<Licence>(`${this.controller}/${id}`);
    }

    pay(id: number): Observable<void> {
        return httpClient.put<void>(`${this.controller}/${id}/pay`, {});
    }

    sendEmail(idLicence: number): Observable<void> {
        return httpClient.post<void>(`${this.controller}/${idLicence}/email`);
    }
}

export const licenciasService = new LicenciasService();