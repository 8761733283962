import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function NotFound(props: RouteComponentProps<{returnUrl: string}>) {
    return (
        <Container className="page">
            <Row>
                <Col lg={6} md={8} sm={10} className="mx-auto">
                    <Card className="shadow-lg border-0">
                        <Card.Body>
                            <div className="text-center">
                                <h1 className="my-3 text-uppercase text-danger">¡Error 404!</h1>
                                <h2>Recurso no encontrado</h2>
                                <p>
                                    El servidor web no encuentra la página o recurso solicitado.<br />
                                    Presione el botón de <strong>Ir a inicio</strong> para regresar en la navegacion del sitio<br />
                                    o puede usar el siguiente botón para regresar a el sitio.
                                </p>
                                <Row>
                                    <Col md={6} sm={8} className="mx-auto">
                                        <Link to="/" className="btn btn-primary btn-block rounded-pill">Ir a inicio</Link>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}