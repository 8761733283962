import * as React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, RouteProps } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import HomePage from './components/landing/home/Home';
import ProductsPage from './components/landing/products/Products';
import LicencesCatalog from './components/licences/Licences';
import ProductsCatalog from './components/products/Products';
import UsersCatalog from './components/users/Users';
import LoginPage from './components/login/Login';
import RegisterPage from './components/register/Register';
import ResetPasswordPage from './components/reset-password/ResetPassword';
import ConfirmResetPasswordPage from './components/reset-password/ConfirmResetPassword';
import NotFound from './components/not-found/NotFound';
import Loading from './components/loading/Loading';
import UserChangePasswordModal from './components/users/user-change-password/UserChangePassword';
import { authService } from './services/auth.service';
import { Rol, ChangePasswordModel } from './models';

import './assets/scss/app.scss';
import logoVertical from './assets/img/logo_vertical.png';

export default function App() {
  const [user, setUser] = React.useState(authService.currentUser.value);
  const [showChangePasswordModal, setShowChangePasswordModal] = React.useState(false);

  React.useEffect(() => {
    authService.currentUser
      .subscribe(loggedUser => {
        setUser(loggedUser);
      });
  }, []);

  function handleShowModalPassword() {
    setShowChangePasswordModal(true);
  }

  function handleCloseModalPassword(model?: ChangePasswordModel) {
    setShowChangePasswordModal(false);

    if (!model) { return; }

    authService.changePassword(model).subscribe();
  }

  return (
    <Router>
      <Navbar className="navbar navbar-expand-lg navbar-dark fixed-top navbar-scrolled" expand="lg">
        <div className="container">
          <HashLink className="navbar-brand" to='/#head'>
            <img src={logoVertical} width="180" alt="EXCELBASIC" />
          </HashLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto my-2 my-lg-0">
              <li className="nav-item">
                <HashLink className="nav-link text-uppercase" to='/#head'>Inicio</HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link text-uppercase" to='/#about'>¿Qué es?</HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link text-uppercase" to='/products#head'>Productos</HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link text-uppercase" to='/#contact'>Contacto</HashLink>
              </li>
              {
                user &&
                <li className="nav-item">
                  <Link className="nav-link text-uppercase" to="/licences">Licencias</Link>
                </li>
              }
              {
                user &&
                <NavDropdown title={user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email} id="navDropdownUser" className="text-uppercase">
                  {
                    user.rol === Rol.Admin &&
                    <>
                      <NavDropdown.Header>Administrar</NavDropdown.Header>
                      <Link className="dropdown-item" to="/manage/products">Productos</Link>
                      <Link className="dropdown-item" to="/manage/users">Usuarios</Link>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item className="dropdown-item" onClick={handleShowModalPassword}>Cambiar contraseña</NavDropdown.Item>
                      <NavDropdown.Divider/>
                    </>
                  }
                  <HashLink className="dropdown-item" to="/" onClick={() => { authService.logout() }}>Cerrar sesión</HashLink>
                </NavDropdown>
              }
              {
                !user &&
                <li className="nav-item">
                  <HashLink className="nav-link text-uppercase" to='/login'>
                    <i className="fas fa-user-circle fa-lg mr-1"></i>
                    Iniciar sesión
                  </HashLink>
                </li>
              }
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div id="head" className="content">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/not-found" component={NotFound} />
          <Route exact path="/products" component={ProductsPage} />
          <Route exact path="/reset-password/:idUser/:resetPasswordToken" component={ConfirmResetPasswordPage}/>
          <ConditionalRoute exact path="/licences" component={LicencesCatalog} predicate={!!user}/>
          <ConditionalRoute exact path="/manage/products" component={ProductsCatalog} predicate={user && user.rol === Rol.Admin} />
          <ConditionalRoute exact path="/manage/users" component={UsersCatalog} predicate={user && user.rol === Rol.Admin} />
          <ConditionalRoute exact path="/login" component={LoginPage} predicate={!user} />
          <ConditionalRoute exact path="/register" component={RegisterPage} predicate={!user} />
          <ConditionalRoute exact path="/reset-password" component={ResetPasswordPage} predicate={!user} />
          <Redirect to="/not-found" />
        </Switch>
      </div>
      <footer className="text-light">
        <div className="container py-3 text-center">
          <p className="m-0 text-uppercase">
            <i className="far fa-copyright" aria-hidden="true"></i> 2019 ExcelBasic todos los derechos reservados.
          </p>
        </div>
      </footer>
      <UserChangePasswordModal show={showChangePasswordModal} 
          onHide={handleCloseModalPassword} 
          onSubmit={handleCloseModalPassword}  
          />
      <Loading />
    </Router>
  );
}

function ConditionalRoute(props: RouteProps & { predicate: boolean }) {
  const { component: Component, predicate, render, ...routeProps } = props;
  return (
    <Route 
      {...routeProps} 
      render={renderProps => predicate ? <Component {...renderProps} /> : <Redirect to="/not-found" />} 
      />
  )
}
