import React from 'react';
import { Form } from 'react-bootstrap';
import uuid from 'uuid';

export default function CheckBox(props: any) {
    return (
        <Form.Group>
            <Form.Check 
                custom
                id={props.meta.id || uuid()}
                type={props.meta.type || 'checkbox'}
                label={props.meta.label}
                isInvalid={props.invalid}
                checked={props.value}
                {...props.handler()} 
            />
            {
                <Form.Control.Feedback className="d-block" type={props.valid ? 'valid' : 'invalid'}>
                    {
                        props.hasError('required') &&
                        <small>{`${props.meta.label} es obligatorio`}</small>
                    }
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
}