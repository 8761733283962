import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '../models';
import { httpClient } from '../helpers/http-client';

class ProductsService {
    readonly controller = 'products';

    getAll(): Observable<Product[]> {
        return httpClient.get<Product[]>(this.controller)
            .pipe(map(products => products.map(x => {
                x.price = +x.price;
                return x;
            })));
    }

    get(id: number): Observable<Product> {
        return httpClient.get<Product>(`${this.controller}/${id}`);
    }

    save(entity: Product): Observable<Product> {
        return httpClient.post<Product>(this.controller, entity);
    }

    update(entity: Product): Observable<Product> {
        return httpClient.put<Product>(`${this.controller}/${entity.idProduct}`, entity);
    }

    delete(id: number): Observable<Product> {
        return httpClient.delete<Product>(`${this.controller}/${id}`);
    }
}

export const productsService = new ProductsService();