import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { FormBuilder, Validators, FieldGroup, FieldControl } from 'react-reactive-form';

import TextInput from '../controls/TextInput';
import { authService } from '../../services/auth.service';
import { RegisterModel } from '../../models/user';
import CustomValidators from '../../helpers/custom-validators';

import logo from '../../assets/img/logo_horizontal.png';

export default function RegisterPage(props: any) {
    const [error, setError] = React.useState<any>(null);
    const [formGroup] = React.useState(FormBuilder.group({
        email: ['', Validators.required],
        firstName: '',
        lastName: '',
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        confirmPassword: ['', [CustomValidators.match('password')]]
    }));

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formGroup.invalid) { return; }

        const model: RegisterModel = Object.assign(formGroup.value);

        authService.register(model)
            .subscribe(
                () => props.history.push('/licences'),
                (err) => showError((err.response && err.response.data && err.response.data.error) || 'Error al iniciar sesión')
            );
    }

    function showError(message: string) {
        setError(message);
        setTimeout(() => setError(null), 10000);
    }

    return (
        <div className="container page">
            <Row>
                <Col lg={6} md={8} sm={10} className="mx-auto text-center">
                    <img src={logo} width="180" className="mb-5" alt="EXCELBASIC" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={8} sm={10} className="mx-auto">
                    <Card className="shadow-lg border-0">
                        <Card.Body>
                            <div className="text-center">
                                <h5>REGISTRO</h5>
                            </div>
                            <FieldGroup
                                control={formGroup}
                                render={() => (
                                    <Form className="form-row" autoComplete="false" onSubmit={handleSubmit}>
                                        <Col md={12}>
                                            <FieldControl
                                                name="email"
                                                render={TextInput}
                                                meta={{ 
                                                    label: 'Correo electrónico'
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FieldControl
                                                name="firstName"
                                                render={TextInput}
                                                meta={{
                                                    label: 'Nombre(s)'
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FieldControl
                                                name="lastName"
                                                render={TextInput}
                                                meta={{
                                                    label: 'Apellido(s)'
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FieldControl
                                                name="password"
                                                render={TextInput}
                                                meta={{ 
                                                    label: 'Contraseña',
                                                    type: 'password'
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FieldControl
                                                name="confirmPassword"
                                                render={TextInput}
                                                meta={{ 
                                                    label: 'Confirmar contraseña',
                                                    type: 'password'
                                                }}
                                            />
                                        </Col>
                                        <Col md={12} className="form-group">
                                            <small>
                                                Al hacer clic en el botón de <strong className="text-primary">Registrarse</strong>, 
                                                confirmas que leíste y aceptas nuestro <Link to="/privacy-policy">Aviso de privacidad</Link> incluido el uso de cookies.
                                            </small>
                                        </Col>
                                        <Col lg={6} md={8} sm={10} className="form-group mx-auto">
                                            <Button variant="primary" type="submit" className="rounded-pill" block>
                                                Registrarse
                                            </Button>
                                        </Col>
                                    </Form>
                                )}
                            />
                            <Row>
                                <Col md={12}>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                </Col>
                                <Col md={12} className="text-center">
                                    <Link to="/login">Tengo una cuenta</Link>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
